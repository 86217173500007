export const defaultColors = {
  pink900: '#CA3398',
  pink800: '#D65CAD',
  blue900: '#002133',
  blue800: '#003756',
  blue700: '#004D78',
  blue600: '#00639A',
  blueLight: '#E6F1F7',
  bluePrimary: '#006EAB',
  bluePrimaryLight: '#80B7D5',
  bluePrimaryDark: '#003756',
  blueSecundary: '#1A7DB3',
  blueSecundaryLight: '#80B7D5',
  blueSecundaryDark: '#002133',
  yellow900: '#CC9600',
  yellow800: '#FFBB00',
  lilac900: '#7A1FA0',
  lilac800: '#AD52D2',
  purple900: '#6E19E4',
  purple800: '#8B47EB',
  grey600: '#666666',
  grey500: '#808080',
  grey400: '#999999',
  green100: '#40BF55',
  greyPrimary: '#808080',
  greyNeutral: '#999999',
  greyLight: '#E6E6E6',
  red100: '#F7D4D4',
  red200: '#F0A8AA',
  red300: '#E87D7F',
  red400: '#E05255',
  red500: '#D9262A',
  red600: '#AD1F22',
  red700: '#821719',
  red800: '#570F11',
  white100: '#FFFFFF',
}

export const sanarColors = {
  ...defaultColors,
  // bluePrimary: '#F56B00',
  // bluePrimaryDark: '#D35C00',
  // blueSecundary: '#FFD9BC',
  // blueSecundaryDark: '#F4BD92',
}
