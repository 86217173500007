import { defaultColors, sanarColors } from './colors'
import { breakpoints } from './breakpoints'

const defaultTheme = {
  colors: defaultColors,
  breakpoints,
}

const sanarTheme = {
  ...defaultTheme,
  colors: sanarColors,
}

export { defaultTheme, sanarTheme }
