import { useCallback, useEffect, useState } from 'react'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { api, guarantorPathApi } from '~/services/config'
import { SessionStorageKeys } from '~/enums/sessionStorageKeys'
import { errorHandler } from '~/utils/errorHandler'
import { useTemplate } from '~/contexts/TemplateContext'
import { asyncSessionStorage } from '~/utils/asyncSessionStorage'
import { useRouter } from 'next/router'

const Interceptors = () => {
  const { setIsLoadingData, setIsSendingData, setStep, setProgressBar } = useTemplate()
  const [retryCount, setRetryCount] = useState(0)
  const MAX_RETRY_COUNT = 3
  const router = useRouter()
  const { code, token } = router.query
  const fallbackCode = code || token

  const getCodeFromQuery = async () => {
    if (typeof window === 'undefined') {
      return ''
    }

    const savedCode = await asyncSessionStorage.getItem(SessionStorageKeys.code)

    if (!!fallbackCode && !savedCode) {
      await asyncSessionStorage.setItem(SessionStorageKeys.code, fallbackCode as string)
    }

    return (fallbackCode || savedCode) as string
  }

  useEffect(() => {
    getCodeFromQuery()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fallbackCode])

  const cancelAllLoadingStates = useCallback(() => {
    if (typeof window !== 'undefined') {
      setIsLoadingData(false)
      setIsSendingData(false)
    }
  }, [setIsLoadingData, setIsSendingData])

  const handleLoadingRequests = useCallback(
    (config: AxiosRequestConfig) => {
      if (typeof window !== 'undefined') {
        if (config.method === 'get') {
          setIsLoadingData(true)
        }

        if (config.method === 'post') {
          setIsSendingData(true)
        }
      }
    },
    [setIsLoadingData, setIsSendingData],
  )

  const handleNextScreen = useCallback(
    (response: AxiosResponse) => {
      if (response.data?.nextScreen) {
        // if we get a nextScreen, we automatically go to the next screen
        setStep(response.data.nextScreen)
      }
    },
    [setStep],
  )

  const handleSaveSessionData = useCallback((response: AxiosResponse) => {
    if (typeof window === 'undefined') {
      return
    }

    if (response.data?.token) {
      // if we get a token, we automatically save it in sessionStorage
      asyncSessionStorage.setItem(SessionStorageKeys.code, response.data.token)
    }

    if (response.data?.nextScreen === 'invalidRequest') {
      // if nextScreen is an invalidRequest, we save all the custom data in sessionStorage
      asyncSessionStorage.setItem(SessionStorageKeys.invalidRequestData, JSON.stringify(response?.data))
    }
  }, [])

  const updateProgressBar = useCallback(
    (response: AxiosResponse) => {
      if (response.data.progress) {
        setProgressBar(response.data.progress)
      }
    },
    [setProgressBar],
  )

  useEffect(() => {
    guarantorPathApi.interceptors.response.use(
      (response) => {
        // reset retry count
        setRetryCount(0)
        cancelAllLoadingStates()
        handleSaveSessionData(response)

        handleNextScreen(response)
        updateProgressBar(response)

        return response
      },
      async (error) => {
        // retry if status is 401
        if (error.response?.status === 401 && retryCount <= MAX_RETRY_COUNT) {
          setRetryCount((oldRetryCount) => oldRetryCount + 1)
          const userToken = await getCodeFromQuery()

          if (userToken) {
            const config = error.config as AxiosRequestConfig
            config.headers!.Authorization = userToken

            return guarantorPathApi.request(config)
          }
        }

        cancelAllLoadingStates()
        handleNextScreen(error?.response || {})
        return errorHandler(error?.response?.data)
      },
    )

    guarantorPathApi.interceptors.request.use(
      async (config) => {
        handleLoadingRequests(config)

        const userToken = await getCodeFromQuery()

        if (userToken) {
          config.headers!.Authorization = userToken
        }

        return config
      },
      (error) => {
        cancelAllLoadingStates()
        return errorHandler(error.response.data)
      },
    )

    api.interceptors.response.use(
      (response) => {
        cancelAllLoadingStates()

        handleSaveSessionData(response)
        handleNextScreen(response)

        return response
      },
      (error) => {
        cancelAllLoadingStates()
        handleNextScreen(error?.response || {})

        return errorHandler(error.response.data)
      },
    )

    api.interceptors.request.use(
      (config) => {
        handleLoadingRequests(config)

        const token = process.env.NEXT_PUBLIC_FRONT_END_TOKEN_END as string

        config.headers!.Authorization = token

        return config
      },
      (error) => {
        cancelAllLoadingStates()
        return errorHandler(error.response.data)
      },
    )

    // cleanup all interceptors because we don't want to have multiple interceptor handlers (this would be a memory leak)
    return () => {
      // @ts-ignore
      ;(guarantorPathApi.interceptors.request.handlers = []),
        // @ts-ignore
        (guarantorPathApi.interceptors.response.handlers = []),
        // @ts-ignore
        (api.interceptors.request.handlers = []),
        // @ts-ignore
        (api.interceptors.response.handlers = [])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cancelAllLoadingStates,
    handleLoadingRequests,
    handleNextScreen,
    handleSaveSessionData,
    updateProgressBar,
    getCodeFromQuery,
    code,
  ])

  return null
}

export default Interceptors
