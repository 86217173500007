import type { AppProps } from 'next/app'
import Head from 'next/head'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from '~/styles/global'
import { defaultTheme, sanarTheme } from '~/styles/theme'
import '~/constants'
import 'react-toastify/dist/ReactToastify.css'
import { TemplateProvider } from '~/contexts/TemplateContext'
import Interceptors from '~/components/templates/Interceptors'
import { Seo } from '~/components/atoms/Seo'

function MyApp({ Component, pageProps }: AppProps) {
  // todo: handle sanar in the future
  const isSanar = false

  return (
    <>
      <Head>
        <title>Plataforma do avalista | Principia</title>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
      </Head>

      <Seo />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalStyles />
      <ThemeProvider theme={isSanar ? sanarTheme : defaultTheme}>
        <TemplateProvider>
          <Interceptors />
          <Component {...pageProps} />
        </TemplateProvider>
      </ThemeProvider>
    </>
  )
}

export default MyApp
