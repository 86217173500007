export const routes = {
  financingSummary: '/resumo',
  basicInfo: '/infos-basicas',
  address: '/endereco',
  emailVerification: '/verificacao/email',
  checkoutEmailVerification: '/verificacao',
  phoneVerification: '/verificacao/telefone',
  preSelfie: '/pre-selfie',
  preDocument: '/pre-documento',
  selfie: '/selfie',
  insertCNH: '/cnh',
  insertRGFront: '/rg-frente',
  insertRGBack: '/rg-verso',
  guarantorAnalysis: '/analise-avalista',
  contractAnalysis: '/analise-contrato',
  incompleteGuarantorAnalysisTimeout: '/analise-incompleta',
  endRequest: '/sucesso',
  creditApproved: '/credito-aprovado',
  incompleteCCB: '/esperando-ccb',
  contractSummary: '/resumo-contrato',
  invalidRequest: '/requisicao-invalida',
  financingSuccess: '/sucesso-financiamento',
  startReview: '/revisao-dados',
  endReview: '/confirmacao-dados',
  incompleteContractAnalysisTimeout: '/analise-incompleta',
}
