import axios from 'axios'
import { ROOT_URL } from '~/constants'
import { errorHandler } from '~/utils/errorHandler'
// import { generateToken } from '~/utils/generateToken'

const api = axios.create({
  baseURL: ROOT_URL?.guarantor || '',
})

const guarantorPathApi = axios.create({
  baseURL: ROOT_URL?.guarantor || '',
})

// guarantorPathApi settings are available at 'src/components/templates/Interceptors.tsx'\
// but since it's inside a context, it can only be called in the client side
// this will only be run on the server side
if (typeof window === 'undefined') {
  api.interceptors.request.use(
    (config) => {
      const token = process.env.NEXT_PUBLIC_FRONT_END_TOKEN_END as string

      config.headers!.Authorization = token

      return config
    },
    (error) => {
      return errorHandler(error.response.data)
    },
  )

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      return errorHandler(error.response.data)
    },
  )
}

export { api, guarantorPathApi }
