const NEXT_PUBLIC_API_ENV = process.env.NEXT_PUBLIC_API_ENV

const URLS = {
  production: {
    guarantor: 'https://ms-guarantor.provi.com.br/v2',
    workerUpload: 'https://workers.provi.com.br/api/file-extension',
    creditSdk: 'https://credito.principia.net',
    smartlook: 'bbefa1bc81816d4fdf3eee63555401d60130cbf8',
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    UNICO_WEBFRAME_VERSION: process.env.NEXT_PUBLIC_UNICO_WEBFRAME_VERSION,
  },
  staging: {
    guarantor: 'https://ms-guarantor-staging.provi.com.br/v2',
    workerUpload: 'https://workersstaging.provi.com.br/api/file-extension',
    creditSdk: 'https://credito-staging.principia.net',
    smartlook: 'bbefa1bc81816d4fdf3eee63555401d60130cbf8',
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    UNICO_WEBFRAME_VERSION: process.env.NEXT_PUBLIC_UNICO_WEBFRAME_VERSION,
  },
  development: {
    guarantor: 'https://ms-guarantor-staging.provi.com.br/v2',
    workerUpload: 'https://workersstaging.provi.com.br/api/file-extension',
    creditSdk: 'https://credito-staging.principia.net',
    smartlook: 'bbefa1bc81816d4fdf3eee63555401d60130cbf8',
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    UNICO_WEBFRAME_VERSION: process.env.NEXT_PUBLIC_UNICO_WEBFRAME_VERSION,
  },
} as const

const setRootUrl = (environment: 'production' | 'staging' | 'development') => {
  switch (environment) {
    case 'production':
      return URLS.production
    case 'staging':
      return URLS.staging
    case 'development':
      return URLS.development
    default:
      return URLS.development
  }
}

export const ROOT_URL = setRootUrl(NEXT_PUBLIC_API_ENV as 'production' | 'staging' | 'development')
export const isProduction = NEXT_PUBLIC_API_ENV === 'production'

export const FRONT_END_TOKEN = process.env.NEXT_PUBLIC_FRONT_END_TOKEN_END
export const MOCKED_TOKEN = process.env.NEXT_PUBLIC_MOCKED_TOKEN
