import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { ISeo } from '~/@types/ISeo'

export const Seo = ({ title, description }: ISeo) => {
  const router = useRouter()

  const seoImage = '/principia_logo_azul.svg'
  const defaultTitle = 'Plataforma do avalista - Principia'
  const defaultDescription =
    'A Principia é um jeito seguro e descomplicado de financiar cursos com condições de pagamento flexíveis, sem juros abusivos e com menos burocracia.'

  return (
    <NextSeo
      title={title || defaultTitle}
      description={description || defaultDescription}
      openGraph={{
        title: title || defaultTitle,
        description: description || defaultDescription,
        locale: 'pt_BR',
        site_name: defaultTitle,
        url: `https://avalista.principia.net${router.asPath}`,
        type: 'website',
        images: [
          {
            url: seoImage,
            alt: 'Principia logo',
          },
        ],
      }}
    />
  )
}
