import { captureException } from '@sentry/nextjs'
import { toast } from 'react-toastify'
import { IError } from '~/@types/IErrors'

export const errorHandler = (error: IError): Promise<string> => {
  if (typeof error === 'string') {
    captureException(new Error(error))
    toast.error(error, {
      toastId: 'errorHandler',
    })
    return Promise.reject(error)
  }

  error?.errors?.forEach((singleError) => {
    captureException(new Error(`${singleError?.name}: ${singleError?.message}`))
    toast.error(singleError.message, {
      toastId: singleError.message,
    })
  })

  // we return only the first error because we need it to return a string
  // we return a string because we're handling errors like this: `typeof result !== 'string' ? setCorrectState(result) : setErrorState(result)`
  // basically correctState is an object, errorState is a string
  return Promise.reject(error?.errors[0]?.message || 'Algo deu errado na requisição')
}
