export const asyncSessionStorage = {
  async getItem(key: string) {
    return Promise.resolve().then(() => {
      return sessionStorage.getItem(key)
    })
  },
  async setItem(key: string, value: string) {
    return Promise.resolve().then(() => {
      sessionStorage.setItem(key, value)
    })
  },
  async removeItem(key: string) {
    return Promise.resolve().then(() => {
      sessionStorage.removeItem(key)
    })
  },
  async clear() {
    return Promise.resolve().then(() => {
      sessionStorage.clear()
    })
  },
}
