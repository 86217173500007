import {
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useCallback,
  useRef,
  MutableRefObject,
} from 'react'
import { IReadProgress } from '~/@types/IDefaultGuarantorPath'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { IStep } from '~/@types/IStep'
import { routes } from '~/constants/routes'

interface IModalProps extends HTMLElement {
  openModalFn: () => void
  closeModalFn: () => void
}

interface TemplateProviderProps {
  children: ReactNode
}

interface ITemplateContextData {
  isLoadingData: boolean
  setIsLoadingData: Dispatch<SetStateAction<boolean>>
  setIsSendingData: Dispatch<SetStateAction<boolean>>
  setProgressBar: Dispatch<SetStateAction<IReadProgress | null>>
  progressBar: IReadProgress | null
  step: IStep
  isSendingData: boolean
  setStep: (step: IStep) => void
  useParams: () => ParsedUrlQuery
  checkoutEmail: string | null
  setCheckoutEmail: Dispatch<SetStateAction<string | null>>
  openFilePicker: (setDocumentImage: (img: string) => void) => void
  picture: string
  setPicture: Dispatch<SetStateAction<string>>
  verificationEmail: string | null
  setVerificationEmail: Dispatch<SetStateAction<string | null>>
  userFullName: string | null
  setUserFullName: Dispatch<SetStateAction<string | null>>
  modalRef: MutableRefObject<IModalProps | undefined>
  openModal: () => void
  closeModal: () => void
}

export const TemplateContext = createContext({} as ITemplateContextData)

export const TemplateProvider = ({ children }: TemplateProviderProps) => {
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isSendingData, setIsSendingData] = useState(false)
  const [progressBar, setProgressBar] = useState<IReadProgress | null>(null)
  const [checkoutEmail, setCheckoutEmail] = useState<string | null>(null)
  const [userFullName, setUserFullName] = useState<string | null>(null)

  const [picture, setPicture] = useState('')
  const [verificationEmail, setVerificationEmail] = useState<string | null>(null)
  const router = useRouter()
  const modalRef = useRef<IModalProps>()

  const openModal = () => {
    modalRef?.current?.openModalFn()
  }

  const closeModal = () => {
    modalRef?.current?.closeModalFn()
  }

  const getStepFromPath = (path: string) => {
    const step = Object.keys(routes).find((key) => routes[key as IStep] === path)
    return step || ''
  }

  const step = useMemo(() => getStepFromPath(router.pathname), [router.pathname]) as IStep

  const setStep = (localStep: IStep) => {
    router.push(routes[localStep])
  }

  const useParams = () => {
    return router.query
  }

  const openFilePicker = useCallback((setDocumentImage) => {
    const input = document.createElement('input') as HTMLInputElement
    input.type = 'file'
    input.accept = '.png,.jpg,.jpeg'
    input.capture = 'environment'
    input.click()

    input.onchange = async () => {
      const file = input.files && input.files[0]
      let mockPNG = false

      if (file) {
        // mocks file type to png in staging env so document can go to analysis
        if (file.name.endsWith('.png') && process.env.NEXT_PUBLIC_API_ENV === 'staging') {
          mockPNG = true
        }

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          // convert image to jpeg
          const image = new Image()
          image.src = reader.result as string
          image.onload = () => {
            const canvas = document.createElement('canvas') as HTMLCanvasElement
            canvas.width = image.width
            canvas.height = image.height
            canvas.getContext('2d')?.drawImage(image, 0, 0)
            const dataURL = canvas.toDataURL('image/jpeg')
            setDocumentImage(dataURL)
          }
        }
      }
    }
  }, [])

  return (
    <TemplateContext.Provider
      value={{
        isLoadingData,
        isSendingData,
        setIsLoadingData,
        setIsSendingData,
        step,
        setStep,
        useParams,
        progressBar,
        setProgressBar,
        verificationEmail,
        setVerificationEmail,
        checkoutEmail,
        setCheckoutEmail,
        openFilePicker,
        picture,
        setPicture,
        userFullName,
        setUserFullName,
        openModal,
        closeModal,
        modalRef,
      }}
    >
      {children}
    </TemplateContext.Provider>
  )
}

export const useTemplate = () => {
  const context = useContext(TemplateContext)

  return context
}
